import { cn } from "@/lib/utils.ts";
import { Link } from "@remix-run/react";
import type { Variants } from "framer-motion";
import { motion } from "framer-motion";
import type React from "react";
import { useCallback } from "react";
import { mergeRefs } from "react-merge-refs";
import { usePopperTooltip } from "react-popper-tooltip";
import { useBrand } from "~zipdeal-ui/contexts-and-providers/BrandProvider.tsx";
import { Spinner } from "~zipdeal-ui/spinners/Spinners.tsx";
import { disabledHandler } from "~zipdeal-ui/utilities/stables.ts";

const popupVariant: Variants = {
	offscreen: {
		scale: 0.5,
		opacity: 0,
	},
	onscreen: {
		scale: 1,
		opacity: 1,
		transition: {
			type: "spring",
			stiffness: 3000,
			damping: 70,
			mass: 3,
		},
	},
};

const tooltipDelayConfig = {
	instant: 0,
	quick: 100,
	short: 500,
	long: 1000,
};

interface ZipButtonProps {
	type?: "button" | "submit" | "reset" | "link";
	ref?: React.Ref<HTMLButtonElement>;
	variant?: "primary" | "secondary" | "tertiary";
	isLoading?: boolean;
	isDisabledButton?: boolean;
	invisible?: boolean;
	className?: string;
	textTransform?: string;
	fontSize?: string;
	padding?: string;
	fontWeight?: string;
	margin?: string;
	shape?: string;
	textColor?: string;
	target?: string;
	textSize?: string;
	colorType?: string;
	to?: string | number;
	variantOverrides?: string;
	display?: string;
	minWidth?: string;
	dataTestId?: string;
	spanTextSize?: string;
	formId?: string;
	name?: string;
	value?: string;
	extraVariantStyles?: string;
	disabledClick?: (event) => void;
	handleClick?: (event) => void;
	onClick?: (event) => void;
	children?: React.ReactNode;
	tooltip?: React.ReactNode;
	tooltipDelay?: "instant" | "long" | "short" | "quick";
	tooltipPosition?:
		| "auto"
		| "auto-start"
		| "auto-end"
		| "top"
		| "top-start"
		| "top-end"
		| "bottom"
		| "bottom-start"
		| "bottom-end"
		| "right"
		| "right-start"
		| "right-end"
		| "left"
		| "left-start"
		| "left-end";
	tooltipClassName?: string;
	tooltipWrapperClassName?: string;
	tooltipOffset?: [number, number];
	tabIndex?: number;
}

export const ZipButton = ({
	type = "button",
	isLoading = false,
	isDisabledButton = false,
	variant = "primary",
	className = "",
	disabledClick = disabledHandler,
	invisible = false,
	textTransform = "uppercase",
	fontWeight = "font-medium",
	fontSize = "text-xs",
	handleClick = undefined,
	onClick = undefined,
	children = null,
	padding = "py-3 px-2",
	textColor = "",
	// textSize = `text-base`,
	textSize = "text-base",
	spanTextSize = "text-xs",
	minWidth = "min-w-32",
	margin = "",
	colorType = "brand",
	shape = "rounded",
	variantOverrides = "",
	dataTestId = undefined,
	to = undefined,
	display = "flex justify-center items-center",
	extraVariantStyles = "",
	tooltip = undefined,
	tooltipDelay = "quick",
	tooltipPosition = "bottom",
	tooltipClassName = "whitespace-nowrap border rounded-md bg-white shadow-sm px-2 py-1",
	tooltipWrapperClassName = "",
	tooltipOffset = [0, 6],
	tabIndex = undefined,
	formId = undefined,
	name = undefined,
	target = undefined,
	value = undefined,
	ref,
}: ZipButtonProps) => {
	let calculatedTextColor = "";
	let variantStyles = "";

	const _onClick = onClick || handleClick;

	const {
		getArrowProps,
		getTooltipProps,
		setTooltipRef,
		setTriggerRef,
		visible,
	} = usePopperTooltip({
		delayShow: tooltipDelayConfig[tooltipDelay],
		placement: tooltipPosition,
		offset: tooltipOffset,
	});
	const { brand } = useBrand();

	switch (variant) {
		case "secondary":
			calculatedTextColor = "text-zd-gray-500";
			variantStyles = cn({
				"bg-white": true,
				[`border-${colorType}-${brand} border-2 hover:border-${colorType}Dark-${brand}`]:
					!isDisabledButton,
				[`border-${colorType}Disabled-${brand} border-2 disabled-button`]:
					isDisabledButton,
				[extraVariantStyles]: !!extraVariantStyles,
			});

			break;
		case "tertiary":
			calculatedTextColor = `text-${colorType}-${brand} hover:text-${colorType}Dark-${brand}`;
			variantStyles = cn({
				underline: true,
				"disabled-button": isDisabledButton,
				[extraVariantStyles]: !!extraVariantStyles,
			});

			break;
		default:
			calculatedTextColor = "text-white";
			variantStyles = cn({
				[`border-${colorType}-${brand} border-2 bg-${colorType}-${brand} hover:bg-${colorType}Dark-${brand}`]:
					!isDisabledButton,
				[`border-${colorType}Disabled-${brand} border-2 bg-${colorType}Disabled-${brand} disabled-button`]:
					isDisabledButton,
				[extraVariantStyles]: !!extraVariantStyles,
			});
			break;
	}

	calculatedTextColor = textColor || calculatedTextColor;
	variantStyles = variantOverrides || variantStyles;

	const onLoadingClick = useCallback((e) => {
		e.preventDefault();
		return;
	}, []);

	const onDisabledClick = useCallback(
		(e) => {
			e.preventDefault();
			disabledClick(e);
			return;
		},
		[disabledClick],
	);

	let clickHandler: ((e) => void) | undefined = useCallback(
		(e) => {
			if (!_onClick) return;
			e.preventDefault();
			_onClick(e);
			return;
		},
		[_onClick],
	);

	// if (type === `submit` && formId) clickHandler = undefined; // Let the submit button do its thing
	if (isLoading) clickHandler = onLoadingClick;
	if (isDisabledButton) clickHandler = onDisabledClick;

	if (type === "link")
		return (
			<>
				<Link
					to={to as string}
					target={target}
					tabIndex={tabIndex}
					data-testid={dataTestId}
					ref={mergeRefs([setTriggerRef, ref])}
					className={cn({
						"relative transition duration-200 ease-in-out": true,
						"cursor-pointer tracking-wider": true,
						"leading-tight": true,
						// [disabledClasses]: isDisabledButton,
						invisible,
						[textSize]: !!textSize,
						[display]: !!display,
						[padding]: !!padding,
						[variantStyles]: !!variantStyles,
						[minWidth]: !!minWidth,
						[textTransform]: !!textTransform,
						[fontSize]: !!fontSize,
						[fontWeight]: !!fontWeight,
						[margin]: !!margin,
						[calculatedTextColor]: !!calculatedTextColor,
						[shape]: !!shape,
						[className]: true,
					})}
					onClick={(event) => clickHandler?.(event)}
				>
					{isLoading && <Spinner />}
					<span
						className={cn({
							invisible: isLoading,
						})}
					>
						<span
							className={cn({
								"text-xs": true,
								[spanTextSize]: !!spanTextSize,
							})}
						>
							{children}
						</span>
					</span>

					{visible && tooltip && (
						<div
							ref={setTooltipRef}
							{...getTooltipProps({
								className: tooltipWrapperClassName,
							})}
						>
							<motion.div
								initial="offscreen"
								whileInView="onscreen"
								variants={popupVariant}
								className={tooltipClassName}
							>
								{tooltip}
								{/*<div {...getArrowProps({ className: `tooltip-arrow` })} /*/}
							</motion.div>
						</div>
					)}
				</Link>
			</>
		);
	return (
		<>
			<button
				type={type}
				tabIndex={tabIndex}
				data-testid={dataTestId}
				form={formId}
				name={name}
				value={value}
				ref={mergeRefs([setTriggerRef, ref])}
				className={cn({
					"relative block transition duration-200 ease-in-out": true,
					"cursor-pointer tracking-wider": true,
					"leading-tight": true,
					// [disabledClasses]: isDisabledButton,
					invisible,
					[textSize]: !!textSize,
					[display]: !!display,
					[padding]: !!padding,
					[variantStyles]: !!variantStyles,
					[minWidth]: !!minWidth,
					[textTransform]: !!textTransform,
					[fontSize]: !!fontSize,
					[fontWeight]: !!fontWeight,
					[margin]: !!margin,
					[calculatedTextColor]: !!calculatedTextColor,
					[shape]: !!shape,
					[className]: true,
				})}
				onClick={(event) => {
					clickHandler?.(event);
				}}
			>
				{isLoading && <Spinner />}
				<span
					className={cn({
						invisible: isLoading,
					})}
				>
					<span
						className={cn({
							"text-xs": true,
							[spanTextSize]: !!spanTextSize,
						})}
					>
						{children}
					</span>
				</span>

				{visible && tooltip && (
					<div
						ref={setTooltipRef}
						{...getTooltipProps({
							className: tooltipWrapperClassName,
						})}
					>
						<motion.div
							initial="offscreen"
							whileInView="onscreen"
							variants={popupVariant}
							className={tooltipClassName}
						>
							{tooltip}
							{/*<div {...getArrowProps({ className: `tooltip-arrow` })} /*/}
						</motion.div>
					</div>
				)}
			</button>
		</>
	);
};
